<template>
	<div class="tournament">
		<SubHeader
			title="JDO Tournament Prize Winner"
			subtitle="JDOトーナメント入賞者"
		/>

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);"> 2024年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/jdo/tournament2023"> 2023年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/jdo/tournament2022"> 2022年度 </a>
				</li>
			</ul>

			<div id="20240825_hyogo" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.8.25 第33回 H.D.O.オープンダーツトーナメント大会</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 優勝</span>
							<img src="/images/jdo/2024hyogo/hyogo_01.jpg" class="img-fluid" />
							<span class="name">高山 得溶</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 準優勝</span>
							<img src="/images/jdo/2024hyogo/hyogo_02.jpg" class="img-fluid" />
							<span class="name">黒川 智成・外筬 久人</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2024hyogo/hyogo_03.jpg" class="img-fluid" />
							<span class="name">谷田 孝夫・高山 得溶</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20240714_ehime" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.7.14 OPEN DARTS TOUNAMENT IN EHIME 2024R6</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">オープンダブルス 優勝</span>
							<img
								src="/images/jdo/202407ehime/ehime_02.jpg"
								class="img-fluid"
							/>
							<span class="name">高山 得溶・大本 直樹</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 優勝</span>
							<img
								src="/images/jdo/202407ehime/ehime_01.jpg"
								class="img-fluid"
							/>
							<span class="name">高山 得溶</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20240512_nara" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.6.30 SADO DARTS TOURNAMENT 2024</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img
								src="/images/jdo/2024saitama/saitama_02.jpg"
								class="img-fluid"
							/>
							<span class="name">大達 浩司・高山 得溶</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 3位タイ</span>
							<img
								src="/images/jdo/2024saitama/saitama_01.jpg"
								class="img-fluid"
							/>
							<span class="name">高山 得溶</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20240512_nara" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.5.12 第4回 奈良ダーツオープントーナメント 2024</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">オープンダブルス 準優勝</span>
							<img src="/images/jdo/2024nara/nara_01.jpg" class="img-fluid" />
							<span class="name">谷田 孝夫・高山 得溶</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2024nara/nara_02.jpg" class="img-fluid" />
							<span class="name">北村 智加</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20231112_kanagawa" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.04.21 KOCHI OPEN DARTS TOURNAMENT vol.14</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-6 text-center offset-lg-4">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2024kochi/kochi_01.jpg" class="img-fluid" />
							<span class="name">北村 智加</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	name: "tournament2020",
	components: {
		SubHeader,
	},
	data() {
		return {};
	},
	mounted: function () {},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
a {
	text-decoration: underline;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.btn-download {
	font-size: 1rem;
	color: #333;
	border: none;
	margin-top: 1rem;
	background: $primary-color;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #333;
		background-color: $secondary-color;
	}
	&:active {
		color: #333;
		background-color: $secondary-color;
	}
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
